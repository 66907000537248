import React from 'react'
import Layout from 'components/Layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
    <div className="section section--padded-big">
      <div className="section__inner">
        <div className="column--measure-medium">
          <h1 className="headline headline--left">Seite nicht gefunden</h1>
          Leider wurde die von Ihnen gesuchte Seite nicht gefunden.
          <div className="margin--t">
            <Link to="/">Zurück zum Start</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
